import React from 'react'
import { FullpageTest } from '../../components/FullpageTest/Fullpage'

export const Test = () => {
  return (
    <div>
      <FullpageTest />
    </div>
  )
}
