import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useSelector } from "react-redux";
import cn from "classnames"
import "./Header.scss"
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';

export const Header = () => {
  const menuState = useSelector((state: any) => state.menu);
  const dispatch = useAppDispatch();
  const [isOpenedMobile, setOpenedMobile] = useState(false)
  // dispatch(editMenu({isWhite: false}));

  return (
    <div className={cn('header', {"header_white": menuState.isWhite})}>
      <div className={cn("header__mobile", {"header__mobile_opened": isOpenedMobile})}>
        <p className="header__mobile-logo">xcom</p>

        <div onClick={() => setOpenedMobile(!isOpenedMobile)} className={cn("header__burger", {"header__burger_opened": isOpenedMobile})}>
          <div className="header__burger-1"></div>
          <div className="header__burger-2"></div>
        </div>
      </div>
      <div className={cn("header__menu", {"header__menu_opened": isOpenedMobile})}>
        <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')}  to={"/"}>XesMeta</NavLink>
        <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')}  to={"/avatares"}>Avatares</NavLink>
        <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')} to={"/graphic"}>Graphic</NavLink>
        {/* <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')}  to={"/gameplay"}>Gameplay</NavLink> */}
        <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')}  to={"/income"}>Income</NavLink>
        <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')}  to={"/ai"}>AI</NavLink>
        <NavLink onClick={() => setOpenedMobile(false)} className={({ isActive }) => (isActive ? 'header__item header__item_active' : 'header__item')} to={"/itworkin"}>ItWorkin</NavLink>
      </div>
    </div>
  )
}
