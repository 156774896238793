import React, { useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';
import { Metamask } from './Metamask/Metamask';
import './Gameplay.scss';

export const Gameplay = () => {
  const dispatch = useAppDispatch();

  const onLeave = (origin, destination, direction, trigger) => {
    if (destination.index === 1 | destination.index === 5) {
      dispatch(
        editMenu({
          isWhite: true
        })
      );
    } else {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
    }

    if (destination.index === 3 | destination.index === 1) {
      document.querySelector(".gameplay__fifth-player").pause()
    }
  }

  useEffect(() => {
    dispatch(
      editMenu({
        isWhite: false,
      })
    );

    window.fullpage_api.silentMoveTo(2);
    window.fullpage_api.silentMoveTo(1);
  }, []);

  return (
    <ReactFullpage
      // scrollingSpeed = {500}
      onLeave={onLeave}
      licenseKey='gplv3-license'
  
      render={() => {
        return (
          <ReactFullpage.Wrapper>
            <div className='gameplay__first section active'>
              <div className='gameplay__wrapper'>
                <div className="gameplay__first-content">
                <img className='gameplay__metamask' src='/images/graphic/metamask.png' />
                  <h1 className='gameplay__first-title'>web 3.0 <br /> registration</h1>
                  <p className='gameplay__first-text'>Powerful protection of <br /> your data and assets</p>
                </div>
                <Metamask/>
              </div>
            </div>
            <div className='gameplay__second section'>
              <div className='gameplay__wrapper'>
                <video
                  className='gameplay__second-video graphic__player'
                  autoPlay={true}
                  muted
                  data-keepplaying
                  loop
                  playsInline={true}
                >
                <source data-src='/videos/anton.mp4' type='video/mp4'></source>
              </video>
                <div className='gameplay__second-content'>
                  <h1 className='gameplay__second-title'>FACENET</h1>
                  <p className='gameplay__second-text'>Is a developed neural <br /> network for accurate <br /> and efficient face <br /> recognition</p>
                </div>
              </div>
            </div>

            {/* <div className='gameplay__third section'>
              <div className='gameplay__wrapper'>
                <h1>MultiView</h1>
                <p className='gameplay__third-text'>Multi-camera for a new level of online interaction</p>
                <div className='gameplay__third-content'>
                  <img className='gameplay__first-image' src='/images/graphic/img1.png' alt='' />
                  <img className='gameplay__first-image' src='/images/graphic/img2.png' alt='' />
                </div>
              </div>
            </div> */}

            <div className='gameplay__fifth section'>
              <div className='gameplay__wrapper'>
                <div className="gameplay__fifth-content">
                  <h1 className='gameplay__fifth-title'>Smart NPC's</h1>
                  <p className='gameplay__fifth-text'>
                    Smart bots that understand you instantly <br /><br />
                    You can ask to create a romantic setting for you: cook dinner, light candles and play music
                  </p>

                </div>
                <video
                  className="gameplay__fifth-player"
                  autoPlay={true}
                  data-keepplaying
                  controls
                  loop
                  playsInline={true}
                >
                  <source data-src="/videos/nltk_gpt_tts.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>

            <div className='gameplay__third section'>
              <div className='gameplay__wrapper'>
                <h1 className='gameplay__marketplace-title'>Marketplace</h1>
                <div className='gameplay__third-content'>
                  <img className='gameplay__marketplace-image' src='/images/graphic/marketplace.png' alt='' />
                  <img className='gameplay__marketplace-image-mobile' src='/images/graphic/marketplace-mobile.png' alt='' />
                </div>
              </div>
            </div>

            {/* <div className='gameplay__last section'>
              <div className='gameplay__wrapper'>
                <h1 className='gameplay__last-title'>no restrictions <br /> on anything</h1>
                <video
                className="graphic__player"
                autoPlay={true}
                muted
                data-keepplaying
                loop
              >
                <source data-src="/videos/lastgameplay.mp4" type="video/mp4"></source>
              </video>
              </div>
            </div> */}
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};
