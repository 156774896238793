import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import './App.scss';
import { Itworkin } from './pages/Itworkin/Itworkin';
import { Header } from './components/Header/Header';
import { Income } from './pages/Income/Income';
import { Graphic } from './pages/Graphic/Graphic';
import { Test } from './pages/Test/Test';
import { Characters } from './pages/Characters/Characters';
import { Gameplay } from './pages/Gameplay/Gameplay';
import { AI } from './pages/AI/AI';

function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route path={'/'} element={<MainPage/>} />
        <Route path={'/itworkin'} element={<Itworkin/>} />
        <Route path={'/income'} element={<Income/>} />
        <Route path={'/ai'} element={<AI/>} />
        <Route path={'/graphic'} element={<Graphic/>} />
        <Route path={'/gameplay'} element={<Gameplay/>} />
        <Route path={'/test'} element={<Test/>} />
        <Route path={'/avatares'} element={<Characters/>} />
      </Routes>
    </>
  );
}

export default App;
