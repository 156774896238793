import React from 'react'
import { ReactSVG } from "react-svg";
import cn from 'classnames';
import "./InfoBlock.scss"

interface InfoBlockProps {
  background: string;
  title?: string;
  text: string
  icon: string;
  className?: string;
}

export const InfoBlock = ({title, text, background, icon, className}:InfoBlockProps) => {
  return (
    <div className={cn('info-block', className)} style={{"backgroundImage": `url(${background})`}}>
      <h1 className='info-block__title'>{title}</h1>
      <div className="info-block__center">
        <ReactSVG className='info-block__icon info-block__icon_center' src={icon} />
        <p className='info-block__text'>{text}</p>
      </div>
      <ReactSVG className='info-block__icon info-block__icon_1' src={icon} />
      <ReactSVG className='info-block__icon info-block__icon_2' src={icon} />
      <ReactSVG className='info-block__icon info-block__icon_3' src={icon} />
      <ReactSVG className='info-block__icon info-block__icon_4' src={icon} />
      <ReactSVG className='info-block__icon info-block__icon_5' src={icon} />
      <ReactSVG className='info-block__icon info-block__icon_6' src={icon} />
    </div>
  )
}
