import React, { useEffect } from 'react';
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';
import './Income.scss';

import ReactFullpage, { fullpage_api } from '@fullpage/react-fullpage';

export const Income = () => {
  const dispatch = useAppDispatch();

  const onLeave = (origin, destination, direction, trigger) => {
    if (destination.index === 2 | destination.index === 3 | destination.index === 4 | destination.index === 6) {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
    } else {
      dispatch(
        editMenu({
          isWhite: true
        })
      );
    }
  }

  useEffect(() => {
    window.fullpage_api.silentMoveTo(2);
    window.fullpage_api.silentMoveTo(1);
    
    dispatch(
      editMenu({
        isWhite: true,
      })
    );
  }, []);

  return (
    <ReactFullpage
      onLeave={onLeave}
      // responsiveWidth={992}
      render={() => {
        return (
          <ReactFullpage.Wrapper>

            <div className='income__main section active'>
              <h1  className='income__main-title'>neural networks <br/> global impact</h1>
              <p className='income__main-text'>How neural networks is taking <br/> over the market</p>
            </div>

            
            <div className='income__block income__block_6 income__only section'>
              <div className='income__wrapper income__only-wrapper'>

                <div className="income__only-images-mobile">
                  <img
                      className='income__only-image-mobile income__only-image-mobile_1'
                      src='/images/income/only-girls-mobile-1.png'
                      alt='Girls'
                    />
                  <img
                    className='income__only-image-mobile income__only-image-mobile_2'
                    src='/images/income/only-girls-mobile-2.png'
                    alt='Girls'
                  />
                </div>

                <div className='income__only-content'>
                  <h2 className={'income__only-title'}>onlyfans</h2>
                  <p className={'income__only-subtitle'}>One of the fastest growing companies in Europe</p>

                  <ul className={'income__only-list'}>
                    <li className={'income__only-list-item'}>Users <div>190 million</div></li>
                    <li className={'income__only-list-item'}>Income per month <div> $100 000</div></li>
                    <li className={'income__only-list-item'}>Сontent creators <div> 2,1 million</div></li>
                  </ul>
                </div>
                <div className='income__only-images'>
                  <img
                    className='income__only-image'
                    src='/images/income/only-girls.png'
                    alt='Girls'
                  />
                </div>

                <div className='income__icon income__icon_1'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_2'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_3'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_4'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
              </div>
            </div>


            <div className='income__block income__block_5 income-monetization section'>
              <div className='income__wrapper'>
                <h1 className='income-monetization__title income-monetization__title1'>monetization</h1>

                <h1 className='income-monetization__title income-monetization__title3'>inner store</h1>
                <p className='income-monetization__subtitle'>Sales commission: <span>30%</span></p>

                <ul className='income-monetization__list'>
                  <li className='income-card income-monetization__list-item'>
                    Skins
                    <div>$0.99 to $9.99</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    Gadgets
                    <div>$1.99 to $19.99</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    Other items
                    <div>$0.49 to $4.99</div>
                  </li>
                </ul>
              </div>
            </div>



            <div className='income__block income__block_6 income-monetization section'>
              <div className='income__wrapper'>

                <h1 className='income-monetization__title income-monetization__title3'>Advertising</h1>

                <ul className='income-monetization__list'>
                  <li className='income-card income-monetization__list-item'>
                    Average cost<br/>
                    per click
                    <div>(CPC): $0.20</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    Average cost<br/>
                    per impression
                    <div>(CPM): $2.00</div>
                  </li>

                </ul>
              </div>
            </div>

            <div className='income__block income__block_6 income-monetization section'>
              <div className='income__wrapper'>

                <h1 className='income-monetization__title income-monetization__title3'>branded gadgets</h1>

                <ul className='income-monetization__list'>
                  <li className='income-card income-monetization__list-item'>
                    Virtual Points<br/>
                    from
                    <div>$199.99</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    Diving suits:
                    <div>from $299.99</div>
                  </li>

                </ul>
              </div>
            </div>


            <div className='income__block income__block_6 income-monetization income-monetization_paid section'>
              <div className='income__wrapper'>

                <h1 className='income-monetization__title income-monetization__title3'>Paid versions of the application</h1>

                <ul className='income-monetization__list'>
                  <li className='income-card income-monetization__list-item'>
                    Basic
                    <div>FREE</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    Advanced
                    <div>$9.99 <br /> per month</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    Premium
                    <div>$14.99<br /> per month</div>
                  </li>
                  <li className='income-card income-monetization__list-item'>
                    VIP
                    <div>$49.99<br /> per month</div>
                  </li>

                </ul>
              </div>
            </div>

          </ReactFullpage.Wrapper>
        );
      }}
    />


  );
};
