import React, { useEffect, useRef, useState } from 'react'
import "./Graphic.scss"
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';
import ReactFullpage from '@fullpage/react-fullpage';

export const Graphic = () => {
  const [autoplayVideo, setAutoplayVideo] = useState(true)

  const dispatch = useAppDispatch();

  const onLeave = (origin, destination, direction, trigger) => {
    if (destination.index === 5) {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
    } else {
      dispatch(
        editMenu({
          isWhite: true
        })
      );
    }
  }

  useEffect(() => {
    dispatch(
      editMenu({
        isWhite: true
      })
    );

    window.fullpage_api.silentMoveTo(2);
    window.fullpage_api.silentMoveTo(1);
  }, [])

  
  return (

    <ReactFullpage
    // scrollingSpeed = {500}
    onLeave={onLeave}

    render={() => {
      
      return (
        <ReactFullpage.Wrapper>
        
          <div className="graphic__block graphic__main section active">
            <div className="graphic__block-content">
              <h1 className="graphic__main-title animate__animated ">UNREAL ENGINE</h1>
              <p className="graphic__main-text animate__animated">Graphics that blur the boundaries between the virtual and real world!</p>

              <video
                className="graphic__player"
                autoPlay={autoplayVideo}
                muted
                data-keepplaying
                loop
                playsInline={true}
              >
                 <source data-src="/new-videos/1graphic-1.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="graphic__block section  graphic_dark">
            <div className="graphic__block-content">
              <p className="graphic__main-text animate__animated">This is a powerful development tool that every year brings the graphic image in computer games and simulations closer to reality.</p>

              <video
                className="graphic__player"
                autoPlay={autoplayVideo}
                muted
                data-keepplaying
                loop
                playsInline={true}
              >
                <source data-src="/new-videos/6.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="graphic__block section">
            <div className="graphic__block-content">
              <p className="graphic__main-text">This engine provides the highest level of graphics, making virtual worlds almost indistinguishable from the real world.</p>

              <video
                className="graphic__player"
                autoPlay={autoplayVideo}
                muted
                data-keepplaying
                loop
                playsInline={true}
                // src="/videos/demo3.mp4"
              >
                <source data-src="/new-videos/2graphic-1.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="graphic__block section">
            <div className="graphic__block-content">
              <p className="graphic__main-text">Photorealistic Textures: With support for high-quality textures and materials</p>
              <video
                className="graphic__player"
                autoPlay={autoplayVideo}
                muted
                data-keepplaying
                loop
                playsInline={true}
                // src="/videos/demo5.mp4"
              >
                <source data-src="/new-videos/4graphic-1.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="graphic__block section">
            <div className="graphic__block-content">
              <p className="graphic__main-text">Character Customization: Unreal Engine has an advanced system that allows you to create photorealistic characters.</p>
            </div>
            <video
                className="graphic__player"
                autoPlay={autoplayVideo}
                muted
                data-keepplaying
                loop
                playsInline={true}
              >
                 <source data-src="/new-videos/3graphic-1.mp4" type="video/mp4"></source>
              </video>
          </div>
      </ReactFullpage.Wrapper>
      );
    }}
  />
  )
}
