import React, { useEffect } from 'react';
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';
import '../Income/Income.scss';

import ReactFullpage, { fullpage_api } from '@fullpage/react-fullpage';

export const AI = () => {
  const dispatch = useAppDispatch();

  const onLeave = (origin, destination, direction, trigger) => {
    if (destination.index === 2 | destination.index === 4 | destination.index === 5 | destination.index === 6) {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
    } else {
      dispatch(
        editMenu({
          isWhite: true
        })
      );
    }
  }

  useEffect(() => {
    window.fullpage_api.silentMoveTo(2);
    window.fullpage_api.silentMoveTo(1);
    
    dispatch(
      editMenu({
        isWhite: true,
      })
    );
  }, []);

  return (
    <ReactFullpage
      onLeave={onLeave}
      // responsiveWidth={992}
      render={() => {
        return (
          <ReactFullpage.Wrapper>
            
            <div className='income__block income__block_6 income__only section'>
              <div className='income__wrapper income__wrapper_ai income__only-wrapper'>

                <div className='income__only-content'>
                  <h2 className={'income__only-title'}>Vivid lifelike reality</h2>
                  <p className={'income__only-subtitle'}>AI backed neural networks give instruments to transfer objects to the metaworld and help to achieve any metamorphosis within. </p>
                </div>
                <div className='income__only-images'>
                <video
                  className="income__only-video"
                  autoPlay={true}
                  data-keepplaying
                  controls
                  loop
                  playsInline
                >
                  <source data-src="/new-videos/ai.mp4" type="video/mp4"></source>
                </video>
                </div>

                <div className='income__icon income__icon_1'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_2'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_3'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_4'>
                  <img src='/images/income/only-icon.svg' alt='gpt icon'/>
                </div>
              </div>
            </div>


            <div className='income__block income__block_3 income__gpt section'>
              <div className='income__wrapper'>
                <div className='income__gpt-content'>
                  <div className='income__gpt-icon'>
                    <img src='/images/income/gpt-image.png' alt='gpt stats'/>
                  </div>
                  <div className='income__gpt-title'>ChatGPT</div>
                  <p className='income__text'>Website traffic per month</p>
                  {/* <div className='income__gpt-image'>
                    <img src='/images/income/gpt-stats.png' alt='gpt stats'/>
                  </div> */}
                  <div className="income__gpt-stats">
                    <div className="income__stat">
                      <div className="income__stat-day">November 2022</div>
                      <div className="income__stat-number">
                        <span>152 700</span>
                      </div>
                    </div>
                    <div className="income__stat">
                      <div className="income__stat-day">December 2022</div>
                      <div className="income__stat-number">
                        <span>266 000 000</span>
                      </div>
                    </div>
                    <div className="income__stat">
                      <div className="income__stat-day">January 2022</div>
                      <div className="income__stat-number">
                        <span>616 000 000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='income__icon income__icon_1'>
                  <img src='/images/income/gpt-icon-2.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_2'>
                  <img src='/images/income/gpt-icon-2.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_3'>
                  <img src='/images/income/gpt-icon-2.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_4'>
                  <img src='/images/income/gpt-icon-2.svg' alt='gpt icon'/>
                </div>
              </div>
            </div>



            <div className='income__block income__block_4 income__mid section'>
              <div className='income__wrapper'>
                <div className='income__mid-content'>
                  <div className='income__mid-images'>
                    <img className='income__mid-image-first' src='/images/income/mid-img-1.png' alt='midjourney image'/>
                    <img className='income__mid-image-absolute' src='/images/income/mid-img-2.png'
                      alt='midjourney image'/>
                  </div>
                  <div className='income__mid-stats'>
                    <h1 className='income__mid-title'>Midjourney</h1>
                    <p className='income__mid-title income__text'>Popularity dynamics</p>
                    <img className='income__mid-graf income__mid-graf_desktop' src='/images/income/mid-graf.png'
                      alt='midjourney image'/>
                    <img className='income__mid-graf income__mid-graf_mobile' src='/images/income/mid-graf-mobile.png'
                      alt='midjourney image'/>
                  </div>
                </div>
                <div className='income__icon income__icon_1'>
                  <img src='/images/income/mid-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_2'>
                  <img src='/images/income/mid-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_3'>
                  <img src='/images/income/mid-icon.svg' alt='gpt icon'/>
                </div>
                <div className='income__icon income__icon_4'>
                  <img src='/images/income/mid-icon.svg' alt='gpt icon'/>
                </div>
              </div>
            </div>

          </ReactFullpage.Wrapper>
        );
      }}
    />


  );
};
