import React, { useEffect } from 'react'
import "./Characters.scss"

import ReactFullpage from '@fullpage/react-fullpage';
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';

export const Characters = () => {
  const dispatch = useAppDispatch();

  const onLeave = (origin, destination, direction, trigger) => {
    if (destination.index === 1) {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
    } else {
      dispatch(
        editMenu({
          isWhite: true
        })
      );
    }
  }

  useEffect(() => {
    window.fullpage_api.silentMoveTo(2);
    window.fullpage_api.silentMoveTo(1);

    dispatch(
      editMenu({
        isWhite: true
      })
    );

  }, [])

  return (
    <ReactFullpage
      onLeave={onLeave}
      render={() => {
        
        return (
          <ReactFullpage.Wrapper>
            <div className="characters__first section active">
              <h3 className='characters__first-title'>be who you want</h3>
              <img className='characters__first-image' src="/images/characters/bestgirl.png" alt="" />
            </div>
            <div className="characters__second section">
               <h1 className='characters__second-title'>Incredible customization options</h1>

               <div className="characters__second-images">
                  <img src="/images/characters/bestgirl1.png" alt="" />
                  <img src="/images/characters/bestgirl2.png" alt="" />
                  <img src="/images/characters/bestgirl3.png" alt="" />
                  <img src="/images/characters/bestgirl4.png" alt="" />
               </div>
            </div>

            <div className="characters__third section">
              <div className="characters__third-content">
               <h1 className='characters__third-title'>Look however you want</h1>

               <div className="characters__third-images">
                <video
                  className="characters__third-video1"
                  muted
                  autoPlay={true}
                  data-keepplaying
                  loop
                  playsInline={true}
                >
                  <source data-src="/videos/gif1.mp4" type="video/mp4"></source>
                </video>
                <video
                  className="characters__third-video1"
                  muted
                  autoPlay={true}
                  data-keepplaying
                  loop
                  playsInline={true}
                >
                  <source data-src="/videos/red.mp4" type="video/mp4"></source>
                </video>
                <video
                  className="characters__third-video1"
                  muted
                  autoPlay={true}
                  data-keepplaying
                  loop
                  playsInline={true}
                  controls={false}
                >
                  <source data-src="/videos/ass.mp4" type="video/mp4"></source>
                </video>

               </div>
              </div>
            </div>

            <div className="graphic__block section">
              <div className="graphic__block-content">
              <h1 className="graphic__main-title animate__animated ">motion capture</h1>
                <p className="graphic__main-text animate__animated">Cinema — like movements by MOCAP technology</p>

                <video
                  className="graphic__player"
                  autoPlay={true}
                  muted
                  data-keepplaying
                  loop
                  playsInline={true}
                >
                  <source data-src="/videos/mocapdemo.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>


            {/* <div className="characters__fourth section">
              <div className="characters__fourth-content characters__wrapper">
               <h1 className='characters__fourth-title'>motion capture</h1>
               <p className='characters__fourth-text'>Cinema — like movements by MOCAP technology</p>

                <video
                  className="graphic__player"
                  muted
                  autoPlay={true}
                  data-keepplaying
                  loop
                >
                  <source data-src="/videos/mocapdemo.mp4" type="video/mp4"></source>
                </video>

              </div>
            </div> */}


            <div className="graphic__block section">
              <div className="graphic__block-content">
              <h1 className="graphic__main-title animate__animated ">face recognition <br /> for your avatar</h1>
                <p className="graphic__main-text animate__animated">Use your face <br /> Easier than everywhere else</p>

                <video
                  className="graphic__player"
                  autoPlay={true}
                  muted
                  data-keepplaying
                  loop
                  playsInline={true}
                >
                  <source data-src="/videos/facerecognitiondemo.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>

            {/* <div className="characters__face section">
              <div className="characters__face-content characters__wrapper">
               <h1 className='characters__face-title'>face recognition <br /> for your avatar</h1>
               <p className='characters__face-text'>Use your face <br /> Easier than everywhere else</p>

                <video
                  className="graphic__player"
                  muted
                  autoPlay={true}
                  data-keepplaying
                  loop
                >
                  <source data-src="/videos/facerecognitiondemo.mp4" type="video/mp4"></source>
                </video>

              </div>
            </div> */}

<div className='gameplay__second section'>
              <div className='gameplay__wrapper'>
                <video
                  className='gameplay__second-video graphic__player'
                  autoPlay={true}
                  muted
                  data-keepplaying
                  loop
                  playsInline
                >
                <source data-src='/videos/anton.mp4' type='video/mp4'></source>
              </video>
                <div className='gameplay__second-content'>
                  <h1 className='gameplay__second-title'>FACENET</h1>
                  <p className='gameplay__second-text'>Is a developed neural <br /> network for accurate <br /> and efficient face <br /> recognition</p>
                </div>
              </div>
            </div>

            <div className="characters__last section">
              <div className="characters__last-content characters__wrapper">
               <h1 className='characters__last-title'>celebrities</h1>
               <p className='characters__last-text'>Interact with your favorite characters</p>

               <img src="/images/characters/laying.png" className="characters__last-image" alt="" />
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  )
};
