import React, { useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { useAppDispatch } from "../../helpers/redux";
import { editMenu } from "../../store/menu/slice";
import ReactPlayer from 'react-player';
import "./MainPage.scss";

export const MainPage = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.fullpage_api.silentMoveTo(2);
    window.fullpage_api.silentMoveTo(1);
    
    dispatch(
      editMenu({
        isWhite: true
      })
    );

    document.body.classList.add('main-page__slider')
    return () => {
      document.body.classList.remove('main-page__slider')
    }

 
  }, [])

  return (
    <ReactFullpage
      // scrollingSpeed = {500}
      fadingEffect
      licenseKey='gplv3-license'

      render={() => {
      return (
        <ReactFullpage.Wrapper>
          <div className=" section">
          <div className="main-page__content">
                  {/* <h1 className="main-page__subtitle">XMETA UNIVERSE</h1>
                  <h1 className="main-page__subtitle2">THE NEW GENERATION ADULT ENTERTAIMENT PLATFORM</h1> */}
                </div>
            <video
                className="graphic__player"
                autoPlay
                muted
                data-keepplaying
                loop
                playsInline
              >
                 <source data-src="/videos/4.mp4" type="video/mp4"></source>
              </video>

              {/* <ReactPlayer
                className="main-page__player"
                // controls={true}
                url={'https://www.youtube.com/watch?v=8SlVghqbQ-Q'}
                playing={true}
                muted={true}
                loop={true}
              /> */}

              {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ?controls=0&autoplay=1&showinfo=0&autohide=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          </div>
              {/* <div className="section active">
                <img className="main-page__image main-page__image_1" src="/images/city/x1.jpg" />
              </div>
              <div className="section">
                <img  className="main-page__image main-page__image_2" src="/images/city/x2.jpg" />
              </div>
              <div className="section">
                <img className="main-page__image main-page__image_3" src="/images/city/x3.jpg" />
              </div>
              <div className="section">
                <img className="main-page__image main-page__image_4" src="/images/city/x4.jpg" />
              </div>
              <div className="section">
                <img className="main-page__image main-page__image_5" src="/images/city/x5.jpg" />
              </div>
              <div className="section">
                <img className="main-page__image main-page__image_6" src="/images/city/x6.jpg" />
              </div>
              <div className="section">
                <div className="main-page__content">
                  <p className="main-page__title">IT workin</p>
                  <h1 className="main-page__subtitle">X Meta-Universe — Sexual Freedom + Social Network</h1>
                </div>
                <img className="main-page__image main-page__image_7" src="/images/city/x7.png" />
              </div> */}
        </ReactFullpage.Wrapper>
      );
    }}
  />
    
  );
};
