import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IMenu {
  isWhite: boolean;
}

const initialState: IMenu | null = {
  isWhite: false
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    editMenu(state, action: PayloadAction<IMenu>) {
      state.isWhite = action.payload.isWhite;
    },
  },
});

export const { editMenu } = menuSlice.actions;

export default menuSlice.reducer;
