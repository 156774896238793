import React, { useEffect, useRef, useState } from 'react'
import { InfoBlock } from '../../components/InfoBlock/InfoBlock'
import { useAppDispatch } from '../../helpers/redux';
import { editMenu } from '../../store/menu/slice';
import ReactFullpage from '@fullpage/react-fullpage';
import "./Itworkin.scss"

export const Itworkin = () => {
  const dispatch = useAppDispatch();

  const onLeave = (origin, destination, direction, trigger) => {
    if (destination.index === 0 | destination.index === 5) {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
    } else {
      dispatch(
        editMenu({
          isWhite: true
        })
      );
    }
  }

  useEffect(() => {
      dispatch(
        editMenu({
          isWhite: false
        })
      );
  }, [])

  return (
    <ReactFullpage
      onLeave={onLeave}
      licenseKey='gplv3-license'
    render={() => {
      return (
        <ReactFullpage.Wrapper>
        <div className="itworkin__about wrapper section active">
          <div className="itworkin__about-content">
            <div className="itworkin__about-text">
              <h1>HELLO!</h1>
              <p>On behalf of the ItWorkin team, we are pleased to welcome you and express our gratitude for your interest, let me tell you in detail about our company</p>
            </div>
            <div className="itworkin__about-images">
              <img className='itworkin__about-image1' src="/images/itworkin/about1.png" alt="" />
              <img className='itworkin__about-image2' src="/images/itworkin/about2.png" alt="" />
            </div>
          </div>
        </div>
        <InfoBlock className="itworkin__block itworkin__block_1 section isDark" background="/images/itworkin/city1.jpg" text="We are a Leading It company located in Holland" title="ITWORKIN" icon='../icons/geo.svg' />
        <InfoBlock className="itworkin__block itworkin__block_2 section isDark" background="/images/itworkin/city2.jpg" text="We have a multifunctional IT team" icon='../icons/cube.svg' />
        <InfoBlock className="itworkin__block itworkin__block_3 section isDark" background="/images/itworkin/city3.jpg" text="Our company is engaged in the development of a full cycle, from development to marketing" icon='../icons/person.svg' />
        <InfoBlock className="itworkin__block itworkin__block_4 section isDark" background="/images/itworkin/city4.jpg" text="Staff around the world, and a lot of experience in development" icon='../icons/planet.svg' />
        <div className="itworkin__block itworkin__video section">
          <h1 className="itworkin__video-title">OUR SKILLS</h1>
          <div className="itworkin__video-player">
            <video
              className="video__player"
              autoPlay={true}
              data-keepplaying
              loop
              muted
              playsInline={true}
              src="/videos/itworkinShowCase.mp4"
            ></video>
          </div>
        </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
  )
}
